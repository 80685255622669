<template>
    <!-- main-product-box -->
    <div class="rain_effect_wrap">
      <div class="rainfall"></div>
      <div class="rainfall"></div>
      <div class="rainfall"></div>
      <div class="rainfall"></div>
      <div class="rainfall"></div>
      <div class="rainfall"></div>
      <div class="rainfall"></div>
      <div class="rainfall"></div>
      <div class="rainfall"></div>
      <div class="rainfall"></div>
      <div class="rainfall"></div>
      <div class="rainfall"></div>
      <div class="rainfall"></div>
      <div class="rainfall"></div>
      <div class="rainfall"></div>
      <div class="rainfall"></div>
      <div class="rainfall"></div>
      <div class="rainfall"></div>
      <div class="rainfall"></div>
      <div class="rainfall"></div>
      <div class="rainfall"></div>
      <div class="rainfall"></div>
      <div class="rainfall"></div>
      <div class="rainfall"></div>
      <div class="rainfall"></div>
      <div class="rainfall"></div>
      <div class="rainfall"></div>
      <div class="rainfall"></div>
      <div class="rainfall"></div>
      <div class="rainfall"></div>
      <div class="rainfall"></div>
      <div class="rainfall"></div>
      <div class="rainfall"></div>
      <div class="rainfall"></div>
      <div class="rainfall"></div>
      <div class="rainfall"></div>
      <div class="rainfall"></div>
      <div class="rainfall"></div>


    </div> 
    <!-- // main-product-box -->

  </template>
  
  <script>
  
  export default {
    name: 'RainEffect',
    
    props: {
      
    },
  
    async fetch() {
     
    },
  
    data() {
      return {
  
      }
    },
  
    mounted() {
      
    },
    
    methods : {
      
    }
    
  }
  </script>